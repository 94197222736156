import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[User SetFilter] Action',
  ResetFilter: '[User ResetFilter] Action',
}

const initialUserState: IUserState = {
  filter: {
    status: '',
    clientId: '',
    roleId: '',
    userTypeId: '',
    businessUnitId: '',
    teamId: '',
    suspended: null,
  },
}

export interface IUserState {
  filter?: {
    status: string
    clientId: string
    roleId: string
    userTypeId: string
    businessUnitId: string
    teamId: string
    suspended: any
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-User', whitelist: ['filter']},
  (state: IUserState = initialUserState, action: ActionWithPayload<IUserState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialUserState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
