import {ApiResponse, ListQueryParams} from '../ApiClient'
import {GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch} from '../Helper'

export class AccountSettingsQueryParams extends ListQueryParams {}

export class AccountSettingsClient {
  static async GetCurrent() {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/current', {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async GetDefault() {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/default', {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async ListAccountTheme(params: AccountSettingsQueryParams) {
    var query =
      (await GetAPIDomain()) + '/account-settings/account-theme' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async UpdateAccountThemeClients(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/account-theme/${id}/update-clients`, {
        headers: GetAPIHeaders(),
        method: 'post',
      })
    )
  }

  static async GetAccountTheme(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/account-theme/${id}`, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async DeleteAccountTheme(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/account-theme/${id}`, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async LinkUserToAccountTheme(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(async () =>
      fetch(apiDomain + `/account-settings/account-theme/${id}/users`, {
        method: 'post',
        headers: await GetAPIHeaders(),
        body: JSON.stringify({
          userIds,
        }),
      })
    )
  }

  static async RemoveUserToAccountTheme(id: string, userId: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/account-theme/${id}/users/${userId}`, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async LinkTeamToAccountTheme(id: string, teamIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(async () =>
      fetch(apiDomain + `/account-settings/account-theme/${id}/teams`, {
        method: 'post',
        headers: await GetAPIHeaders(),
        body: JSON.stringify({
          teamIds,
        }),
      })
    )
  }

  static async RemoveTeamToAccountTheme(id: string, teamId: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/account-theme/${id}/teams/${teamId}`, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async UpdateThemeColor(
    id: string,
    primary: string,
    secondary: string,
    iconPrimary: string,
    iconSecondary: string,
    onSite: string,
    remote: string,
    leave: string,
    noResponse: string,
    success: string,
    error: string,
    whatsOnHoliday: string,
    whatsOnEvent: string,
    highlightTeam: string,
    available: string,
    reserved: string,
    inUse: string,
    booked: string,
    item: string,
    activity: string,
    name: string,
    active: boolean,
    updateValues: boolean,
    audienceType: any
  ) {
    var apiDomain = await GetAPIDomain()
    const values: any[] = [
      {
        key: 'Primary',
        value: primary,
      },
      {
        key: 'Secondary',
        value: secondary,
      },
      {
        key: 'IconPrimary',
        value: iconPrimary,
      },
      {
        key: 'IconSecondary',
        value: iconSecondary,
      },
      {
        key: 'OnSite',
        value: onSite,
      },
      {
        key: 'Remote',
        value: remote,
      },
      {
        key: 'Leave',
        value: leave,
      },
      {
        key: 'NoResponse',
        value: noResponse,
      },
      {
        key: 'Success',
        value: success,
      },
      {
        key: 'Error',
        value: error,
      },
      {
        key: 'WhatsOnHoliday',
        value: whatsOnHoliday,
      },
      {
        key: 'WhatsOnEvent',
        value: whatsOnEvent,
      },
      {
        key: 'HighlightTeam',
        value: highlightTeam,
      },

      {
        key: 'Available',
        value: available,
      },

      {
        key: 'Reserved',
        value: reserved,
      },
      {
        key: 'InUse',
        value: inUse,
      },
      {
        key: 'Booked',
        value: booked,
      },
      {
        key: 'Item',
        value: item,
      },
      {
        key: 'Activity',
        value: activity,
      },
    ]

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/account-theme', {
        method: 'patch',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          id,
          name,
          active,
          values: updateValues ? values : null,
          updateValues,
          audienceType,
        }),
      })
    )
  }

  static async CreateThemeColor(name: string, audienceTypeId: any) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/account-theme', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          name,
          audienceTypeId,
        }),
      })
    )
  }

  static async CopyThemeColor(themeId: string, name: string, audienceTypeId: any) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/account-theme/copy', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({themeId, name, audienceTypeId}),
      })
    )
  }

  static async CreateLocalization(name: string, audienceTypeId: any) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/localization', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          name,
          audienceTypeId,
        }),
      })
    )
  }

  static async GetLocalization(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/localization/${id}`, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async DeleteLocalization(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/localization/${id}`, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async ListLocalization(params: AccountSettingsQueryParams) {
    var query =
      (await GetAPIDomain()) + '/account-settings/localization' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async UpdateLocalizationClients(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/localization/${id}/update-clients`, {
        headers: GetAPIHeaders(),
        method: 'post',
      })
    )
  }

  static async UpdateLocalizations(
    id: string,
    home: string,
    onSite: string,
    notWorking: string,
    monday: string,
    tuesday: string,
    wednesday: string,
    thursday: string,
    friday: string,
    saturday: string,
    sunday: string,
    name: string,
    active: boolean,
    updateValues: boolean,
    audienceType: any
  ) {
    var apiDomain = await GetAPIDomain()
    const values: any[] = [
      {
        key: 'Home',
        value: home,
      },
      {
        key: 'OnSite',
        value: onSite,
      },
      {
        key: 'NotWorking',
        value: notWorking,
      },
      {
        key: 'Monday',
        value: monday,
      },
      {
        key: 'Tuesday',
        value: tuesday,
      },
      {
        key: 'Wednesday',
        value: wednesday,
      },
      {
        key: 'Thursday',
        value: thursday,
      },
      {
        key: 'Friday',
        value: friday,
      },
      {
        key: 'Saturday',
        value: saturday,
      },
      {
        key: 'Sunday',
        value: sunday,
      },
    ]

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/account-settings/localization', {
        method: 'patch',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          id,
          name,
          active,
          values: updateValues ? values : null,
          updateValues,
          audienceType,
        }),
      })
    )
  }

  static async LinkUserToLocalization(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(async () =>
      fetch(apiDomain + `/account-settings/localization/${id}/users`, {
        method: 'post',
        headers: await GetAPIHeaders(),
        body: JSON.stringify({
          userIds,
        }),
      })
    )
  }

  static async RemoveUserToLocalization(id: string, userId: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/localization/${id}/users/${userId}`, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async LinkTeamToLocalization(id: string, teamIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(async () =>
      fetch(apiDomain + `/account-settings/localization/${id}/teams`, {
        method: 'post',
        headers: await GetAPIHeaders(),
        body: JSON.stringify({
          teamIds,
        }),
      })
    )
  }

  static async RemoveTeamToLocalization(id: string, teamId: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/account-settings/localization/${id}/teams/${teamId}`, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }
}
