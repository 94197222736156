import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {string} from 'yup'
import {DashboardViews} from '../DashboardWrapper'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetView: '[Dashboard SetView] Action',
  SetTeamFilter: '[Dashboard SetTeamFilter] Action',
  SetSiteFilter: '[Dashboard SetSiteFilter] Action',
  SetShowPlanner: '[Dashboard SetPlannerFilter] Action',
}

const initialDashboardState: IDashboardState = {
  view: {
    value: DashboardViews.TeamView,
    label: DashboardViews.TeamView,
  },

  selectedTeams: [
    {
      value: 'All',
      label: 'All',
    },
  ],
  teamDate: moment().format('YYYY-MM-DD'),

  selectedSites: [
    {
      value: 'All',
      label: 'All',
    },
  ],
  siteDate: moment().format('YYYY-MM-DD'),
  showPlanner: false,
}

export interface IDashboardState {
  view: any

  selectedTeams: any[]
  teamDate: string

  selectedSites: any[]
  siteDate: string

  showPlanner: boolean
}

export const reducer = persistReducer(
  {storage, key: 'v100-Dashboard', whitelist: ['filter']},
  (state: IDashboardState = initialDashboardState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.SetView: {
        const view = action.payload?.view
        return {...state, view}
      }
      case actionTypes.SetTeamFilter: {
        const {selectedTeams, teamDate} = action.payload
        return {...state, selectedTeams, teamDate}
      }
      case actionTypes.SetSiteFilter: {
        const {selectedSites, siteDate} = action.payload
        return {...state, selectedSites, siteDate}
      }

      case actionTypes.SetShowPlanner: {
        const {showPlanner} = action.payload
        return {...state, showPlanner}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  setView: ({view}: any) => ({
    type: actionTypes.SetView,
    payload: {view},
  }),
  setTeamFilter: ({selectedTeams, teamDate}: any) => ({
    type: actionTypes.SetTeamFilter,
    payload: {selectedTeams, teamDate},
  }),
  setSiteFilter: ({selectedSites, siteDate}: any) => ({
    type: actionTypes.SetSiteFilter,
    payload: {selectedSites, siteDate},
  }),
  setPlannerFilter: ({showPlanner}: any) => ({
    type: actionTypes.SetShowPlanner,
    payload: {showPlanner},
  }),
}

export function* saga() {}
