import React, {Component, useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import Moment from 'react-moment'
import {
  Badge,
  Spinner,
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  InputGroup,
  FormGroup,
  Form,
} from 'reactstrap'
import {Formik, ErrorMessage} from 'formik'
import _ from 'lodash'
import TeamSelector from '../../../common/components/selectors/TeamSelector'
import {UsersClient} from '../../../infrastracture/api/UsersClient'
import {TableHeader} from '../../../common/components/TableHeader'
import moment from 'moment'
import {TeamsClient} from '../../../infrastracture/api/TeamsClient'
import Select from 'react-select'
import {months, years} from '../../../common/constants/dates'
import customStyles from '../../../common/components/selectors/customStyles'
import {ChartColors} from '../../../common/constants/chartColors'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'

interface Props {
  teamIds: string[]
  fromDashboard?: boolean
  dashboardMonth?: any
  dashboardYear?: any
  trigger?: boolean
}

const TeamPlanner: React.FC<Props> = (props: any) => {
  const {teamIds, fromDashboard, dashboardMonth, dashboardYear, trigger} = props

  const colors: any = useSelector<RootState>(
    ({accountSetting}) => accountSetting.color,
    shallowEqual
  ) as any

  const localizations: any = useSelector<RootState>(
    ({accountSetting}) => accountSetting.localization,
    shallowEqual
  ) as any

  const [month, setMonth] = useState(parseInt(moment().format('M')))
  const [year, setYear] = useState(parseInt(moment().format('YYYY')))
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [data, setData] = useState<any>({})
  const [selectedData, setSelectedData] = useState<any>({})
  const [daysOfMonth, setDaysOfMonth] = useState<any[]>([])
  const [mappeddaysOfMonth, setMappedDaysOfMonth] = useState<any[]>([])

  useEffect(() => {
    if (teamIds.length > 0 && !fromDashboard && month && year) {
      getDaysByMonth()
      populateData()
    }
  }, [teamIds, month, year])

  useEffect(() => {
    if (teamIds.length > 0 && fromDashboard) {
      getDaysByMonth()
      populateData()
    }
  }, [trigger])

  useEffect(() => {
    if (dashboardYear) setYear(dashboardYear)
    if (dashboardMonth) setMonth(dashboardMonth)
  }, [dashboardMonth, dashboardYear])

  const populateData = async () => {
    setLoading(true)

    var response: any

    // if (fromDashboard)
    //   response = await TeamsClient.GetMonthlyPlanner(teamIds, dashboardMonth, dashboardYear)
    // else
    response = await TeamsClient.GetMonthlyPlanner(teamIds, month, year)

    if (!response.authenticated) {
      setLoading(false)
    } else {
      setData(response.data)
      setLoading(false)
      setIsSubmitting(false)
    }
    setInitialized(true)
  }
  const getDaysByMonth = () => {
    const daysInMonth = moment(`${year}-${month}`).daysInMonth()
    var dates = Array.from({length: daysInMonth}, (v, k) => {
      return {
        value: k + 1,
        label: moment(`${year}-${month}`)
          .date(k + 1)
          .format('ddd'),
      }
    })

    setDaysOfMonth(dates)
  }

  const colorColumn = (state: string) => {
    switch (state) {
      case 'NoResponse':
        return <td style={{background: colors.NoResponse}}> </td>
      case 'WorkFromOffice':
        return <td style={{background: colors.OnSite}}> </td>
      case 'WorkFromHome':
        return <td style={{background: colors.Home}}> </td>
      case 'NotWorking':
        return <td style={{background: colors.NotWorking}}> </td>
    }
  }

  return (
    <>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              <h3>Team Planner</h3>
            </span>
          </h3>

          {!fromDashboard && (
            <div className='card-toolbar'>
              <Select
                onChange={({label, value}: any) => {
                  setMonth(value)
                }}
                defaultValue={{
                  value: parseInt(moment().format('M')),
                  label: moment().format('MMMM'),
                }}
                options={months}
                placeholder={'Select Month'}
                styles={customStyles}
                menuPortalTarget={document.body}
                isDisabled={loading}
              />
              <div style={{paddingRight: '10px'}}></div>
              <Select
                onChange={({label, value}: any) => {
                  setYear(value)
                }}
                defaultValue={{
                  value: parseInt(moment().format('YYYY')),
                  label: moment().format('YYYY'),
                }}
                options={years}
                placeholder={'Select Year'}
                styles={customStyles}
                menuPortalTarget={document.body}
                isDisabled={loading}
              />
            </div>
          )}
        </div>

        <div className='card-body table-responsive'>
          {loading && (
            <div className='d-flex align-items-center'>
              <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
            </div>
          )}
          {!loading && (
            <table
              className='table table-row-bordered table-row-gray-300 align-middle'
              aria-labelledby='tabelLabel'
            >
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <>
                    <th className='pl-1 bg-white'>DOW</th>
                    {daysOfMonth.map((day) => (
                      <th>{day.label.charAt(0)}</th>
                    ))}
                  </>
                </tr>{' '}
                <tr className='fw-bolder text-muted bg-light'>
                  <>
                    <th className='pl-1 bg-white'>{month && moment.months(month - 1)}</th>
                    {daysOfMonth.map((day) => (
                      <th>{day.value}</th>
                    ))}
                  </>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((user: any) => {
                    const {entries} = user
                    return (
                      <tr key={user.userId}>
                        <td className='text-gray-600 fw-bolder align-middle'>{user.fullName}</td>
                        {entries.map((entry: any) => colorColumn(entry.state))}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  )
}

export default TeamPlanner
