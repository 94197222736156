/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {toAbsoluteUrl} from '../../../helpers'
import {Languages} from './Languages'

export const addDefaultSrc = (ev: any) => {
  ev.target.src = toAbsoluteUrl('/media/avatars/blank.png')
}

const HeaderUserMenu: FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              width='35'
              height='35'
              src={user.profilePhoto}
              alt=''
              onError={addDefaultSrc}
            ></img>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.name} {user.surname}
            </div>
            <span className='fw-bold text-muted fs-7'>{user.email}</span>
            <span className='fw-bold text-muted fs-7'>{user.account?.companyName}</span>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
