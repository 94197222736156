import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {MyDayStatus} from '../../planner/enums'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[MyDay SetFilter] Action',
  ResetFilter: '[MyDay ResetFilter] Action',
}

const initialMyDayState: IMyDayState = {
  filter: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    status: MyDayStatus.Confirmed,
    teamId: '',
    userId: '',
    siteId: '',
  },
}

export interface IMyDayState {
  filter?: {
    startDate: string
    endDate: string
    status: any
    teamId: any
    userId: any
    siteId: any
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-MyDay', whitelist: ['filter']},
  (state: IMyDayState = initialMyDayState, action: ActionWithPayload<IMyDayState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialMyDayState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
