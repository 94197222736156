/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { checkUserAccess, discoverCurrentAccount, getssoLink, login } from '../redux/AuthCRUD'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PermissionsClient } from '../../../infrastracture/api/PermissionsClient'
import { AccountIdentityProvider } from '../enums/identityProvider'
import qs from 'query-string'
import { AccountSettingsClient } from '../../../infrastracture/api/AccountSettingsClient'
import { ChartColors, ChartLabels } from '../../../common/constants/chartColors'
import * as accountSettings from '../../account-themes/redux/AccountSettingRedux'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    // password: Yup.string()
    //   .min(3, 'Minimum 3 symbols')
    //   .max(50, 'Maximum 50 symbols')
    //   .required('Password is required'),
})

const initialValues = { email: '', password: '' }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

enum FormSteps {
    VerifyEmail,
    Authentication,
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const [hasNoCrmAccess, setHasNoCrmAccess] = useState(false)
    const dispatch = useDispatch()

    const [step, setStep] = useState<FormSteps>(FormSteps.VerifyEmail)

    const fetchAccountSettings = async () => {
        var defaultSettings = await AccountSettingsClient.GetDefault()

        const { data } = defaultSettings

        var color = data.accountThemes[0]

        if (color?.values) {
            const onSiteColor =
                color.values.find((x: any) => x.key === 'OnSite')?.value ?? ChartColors.OnSite
            const homeColor = color.values.find((x: any) => x.key === 'Remote')?.value ?? ChartColors.Home
            const noResponseColor =
                color.values.find((x: any) => x.key === 'NoResponse')?.value ?? ChartColors.NoResponse
            const notWorkingColor =
                color.values.find((x: any) => x.key === 'Leave')?.value ?? ChartColors.NotWorking
            const whatsOnHoliday = color.values.find((x: any) => x.key === 'WhatsOnHoliday')?.value
            const whatsOnEvent = color.values.find((x: any) => x.key === 'WhatsOnEvent')?.value

            var mappedColors = {
                OnSite: onSiteColor,
                Home: homeColor,
                NoResponse: noResponseColor,
                NotWorking: notWorkingColor,
                WhatsOnHoliday: whatsOnHoliday,
                WhatsOnEvent: whatsOnEvent,
            }

            dispatch(accountSettings.Actions.setColor(mappedColors))
        }

        var localization = data.localizations[0]

        if (localization?.values) {
            const onSiteLabel =
                localization.values.find((x: any) => x.key === 'OnSite')?.value ?? ChartLabels.OnSite
            const homeLabel =
                localization.values.find((x: any) => x.key === 'Home')?.value ?? ChartLabels.Home
            const noResponseLabel = ChartLabels.NoResponse
            const notWorkingLabel =
                localization.values.find((x: any) => x.key === 'NotWorking')?.value ??
                ChartLabels.NotWorking

            var mappedLabels = {
                OnSite: onSiteLabel,
                Home: homeLabel,
                NoResponse: noResponseLabel,
                NotWorking: notWorkingLabel,
            }

            dispatch(accountSettings.Actions.SetLocalization(mappedLabels))
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting, setFieldError }) => {
            setHasNoCrmAccess(false)
            setLoading(true)
            setSubmitting(true)

            if (step === FormSteps.VerifyEmail) {
                const domain = values.email.split('@')[1]

                discoverCurrentAccount(values.email)
                    .then(async (result: any) => {
                        const { ok, data } = result
                        const { identityProvider, accountId } = data

                        if (ok) {

                            localStorage.setItem('tenantAccountId', accountId);

                            if (identityProvider === AccountIdentityProvider.None) {
                                setStep(FormSteps.Authentication)
                            } else if (identityProvider === AccountIdentityProvider.AzureAD) {
                                const ssoResult = await getssoLink(accountId || '')

                                window.location.href = ssoResult.data
                            }
                        } else {
                            setFieldError('email', data.errorMessage)
                        }

                        setLoading(false)
                        setSubmitting(false)
                    })
                    .catch((reason) => console.log(reason))
            } else if (step === FormSteps.Authentication) {
                const userAccess = await checkUserAccess(values.email, localStorage.getItem('tenantAccountId')?.toString())
                const { hasCRMAccess, suspended } = userAccess
                if (suspended) {
                    setStatus('User account suspended')
                    setLoading(false)
                    setSubmitting(false)
                } else if (!hasCRMAccess) {
                    setStatus('No CRM Access')
                    setLoading(false)
                    setSubmitting(false)
                } else {
                    login(values.email, values.password).then(async (result) => {
                        if (result.successful) {
                            const accessToken = localStorage.getItem('acess-token') || ''
                            dispatch(auth.actions.login(accessToken))
                            var permissions = await PermissionsClient.ForCurrentUser()
                            if (permissions.successful) {
                                if (permissions.data) {
                                    var mappedPermissions = permissions.data.data.map((p: any) => p.id)
                                    dispatch(auth.actions.setUserPermission(mappedPermissions))
                                }

                                await fetchAccountSettings()
                            }

                            setLoading(false)
                            setSubmitting(false)
                        } else {
                            if (result.error_description === "password_expired") {
                                setStatus('Your password has expired, please reset it using the forgotten password flow.')
                            } else {
                                setStatus('Incorrect login details')
                            }
                            setLoading(false)
                            setSubmitting(false)
                        }
                    })
                }
            }
        },
    })

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <img
                    alt='Logo'
                    className='h-150px logo mb-5'
                    src={toAbsoluteUrl('/media/logos/daysee_logo.png')}
                />
                <h1 className='text-dark mb-3'>Sign In to Daysie</h1>
                {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
            </div>
            {/* begin::Heading */}

            {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                // <div className='mb-10 bg-light-info p-8 rounded'>
                //   <div className='text-info'>
                //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                //     continue.
                //   </div>
                // </div>
                ''
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                    disabled={step === FormSteps.Authentication}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            {step === FormSteps.Authentication && (
                <>
                    <div className='fv-row mb-5'>
                        <div className='d-flex justify-content-between mt-n5'>
                            <div className='d-flex flex-stack mb-2'>
                                {/* begin::Label */}
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                                {/* end::Label */}
                            </div>
                        </div>
                        <input
                            placeholder='Password'
                            type='password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid':
                                        (formik.touched.password && formik.errors.password) || !formik.values.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}
                    <div className='fv-row mb-5'>
                        <Link
                            to='/forgot-password'
                            className='link-primary fs-6 fw-bolder'
                            style={{ marginLeft: '5px' }}
                        >
                            Forgot Password?
                        </Link>
                    </div>
                </>
            )}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid || loading}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}
        </form>
    )
}
