import {ApiResponse, ListQueryParams} from '../ApiClient'
import {GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch, GetApiHeadersFormData} from '../Helper'

export class SiteQueryParams extends ListQueryParams {
  WithUserId(value: string) {
    this.WithParam('userId', value)
    return this
  }
  WithSiteTypeId(value: string) {
    this.WithParam('siteTypeId', value)
    return this
  }
  WithStatus(value: string) {
    this.WithParam('status', value)
    return this
  }
  WithDate(value: boolean) {
    this.WithParam('date', value)
    return this
  }
  WithNoticeId(value: string) {
    this.WithParam('noticeId', value)
    return this
  }
}

export class SitesClient {
  static async List(params: SiteQueryParams) {
    var query = (await GetAPIDomain()) + '/sites' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async GetSummary(id: string, month: number, year: number) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/sites/monthly-summary' + `?id=${id}&month=${month}&year=${year}`, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + '/sites/' + id

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/sites/' + id, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async Create(
    name: string,
    latitude: string,
    longitude: string,
    address1: string,
    address2: string,
    address3: string,
    city: string,
    state: string,
    country: string,
    postcode: string,
    status: string,
    color: string,
    siteTypeId?: any,
    maxLimit?: number | null
  ) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/sites/', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          name: name,
          latitude: latitude,
          longitude: longitude,
          address1: address1,
          address2: address2,
          address3: address3,
          city: city,
          state: state,
          country: country,
          postcode: postcode,
          status: status,
          siteTypeId: siteTypeId,
          maxLimit,
          color,
        }),
      })
    )
  }

  static async Update(
    id: string,
    name: string,
    latitude: string,
    longitude: string,
    address1: string,
    address2: string,
    address3: string,
    city: string,
    state: string,
    country: string,
    postcode: string,
    status: string,
    color: string,
    siteTypeId?: any,
    maxLimit?: number | null
  ) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/sites/' + id, {
        method: 'patch',
        headers: GetAPIHeadersPatch(),
        body: JSON.stringify([
          {
            op: 'replace',
            path: 'name',
            value: name,
          },
          {
            op: 'replace',
            path: 'latitude',
            value: latitude,
          },
          {
            op: 'replace',
            path: 'longitude',
            value: longitude,
          },
          {
            op: 'replace',
            path: 'address1',
            value: address1,
          },
          {
            op: 'replace',
            path: 'address2',
            value: address2,
          },
          {
            op: 'replace',
            path: 'address3',
            value: address3,
          },
          {
            op: 'replace',
            path: 'city',
            value: city,
          },
          {
            op: 'replace',
            path: 'state',
            value: state,
          },
          {
            op: 'replace',
            path: 'country',
            value: country,
          },
          {
            op: 'replace',
            path: 'postcode',
            value: postcode,
          },
          {
            op: 'replace',
            path: 'status',
            value: status,
          },
          {
            op: 'replace',
            path: 'siteTypeId',
            value: siteTypeId,
          },
          {
            op: 'replace',
            path: 'maxLimit',
            value: maxLimit,
          },
          {
            op: 'replace',
            path: 'color',
            value: color,
          },
        ]),
      })
    )
  }

  static async UploadSiteImage(siteId: string, file: any) {
    let formData = new FormData()
    formData.append('file', file)

    var accessToken = localStorage.getItem('acess-token')

    const response = await fetch((await GetAPIDomain()) + '/sites/upload-site-image/' + siteId, {
      method: 'post',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: formData,
    })

    if (!response.ok) {
      return false
    }

    const data = await response.json()
  }

  static async BulkUpload(siteTypeId: string, file: any) {
    let formData = new FormData()
    formData.append('siteTypeId', siteTypeId)
    formData.append('file', file)
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/sites/bulk', {
        method: 'post',
        headers: GetApiHeadersFormData(),
        body: formData,
      })
    )
  }
}
