import axios from 'axios'
import { httpClientWithAuth } from '../../../../setup/axios/SetupAxios'
import { ApiResponse } from '../../../infrastracture/ApiClient'
import { GetAPIDomain, GetAPIHeaders, GetAuthAPIDomain } from '../../../infrastracture/Helper'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

export async function login(username: string, password: string) {
    var accountid = localStorage.getItem('accountId') || ''
    let formData = new URLSearchParams()
    formData.append('username', username)
    formData.append('password', password)
    formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc')
    formData.append('grant_type', 'password')
    formData.append('account_id', accountid?.toString())

    var authDomain = await GetAuthAPIDomain()

    const response = await fetch(authDomain + '/connect/token', {
        body: formData,
        method: 'post',
    })

    const data = await response.json()

    if (response.ok) {
        localStorage.setItem('domain-auth', authDomain || '')
        localStorage.setItem('acess-token', data.access_token)
        localStorage.setItem('refresh-token', data.refresh_token)
        localStorage.setItem('expires', new Date(new Date().getTime() + 50 * 60 * 1000).toISOString())
        return {
            successful: true,
        }
    }

    var tId = localStorage.getItem('tenantAccountId');
    localStorage.clear()
    if (tId)
        localStorage.setItem('tenantAccountId', tId);

    return {
        successful: false,
        error: data.error,
        error_description: data.error_description
    }
}

export async function checkUserAccess(email: string, tenantId?: string) {
    var response = await fetch((await GetAPIDomain()) + `/users/check-user-access?email=${email}`, {
        headers: {
            'x-account-id': tenantId ?? "",
        },
    })

    const data = await response.json()

    return data
}

export async function ssoLogin(accountId: string, openIdToken: string) {
    let formData = new URLSearchParams()
    formData.append('grant_type', 'open_id')
    formData.append('account_id', accountId)
    formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc')
    formData.append('open_id_token', openIdToken)

    var domain = await GetAPIDomain()

    const response = await fetch(domain + '/connect/token', {
        body: formData,
        method: 'post',
    })

    const data = await response.json()

    if (response.ok) {
        localStorage.setItem('api-domain', domain || '')
        localStorage.setItem('acess-token', data.access_token)
        localStorage.setItem('refresh-token', data.refresh_token)
        localStorage.setItem('expires', new Date(new Date().getTime() + 50 * 60 * 1000).toISOString())
        return true
    }

    localStorage.clear()
    return false
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
    })
}

export function LogOut() {
    localStorage.removeItem('acess-token')
    localStorage.removeItem('refresh-token')
    localStorage.removeItem('is-on-web')

    localStorage.clear()
}

export function IsAuthenticated() {
    if (localStorage.getItem('acess-token') == null) return false

    var token = localStorage.getItem('acess-token')
    if (token != null && token.length === 0) return false

    return true
}

export async function RefreshToken() {
    var token = localStorage.getItem('refresh-token')

    if (!token) {
        localStorage.clear()
        return false;
    }

    let formData = new URLSearchParams()
    formData.append('refresh_token', token)
    formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc')
    formData.append('grant_type', 'refresh_token')

    const response = await fetch((await GetAuthAPIDomain()) + '/connect/token', {
        body: formData,
        method: 'post',
    })

    if (!response.ok) {
        localStorage.clear()
        return false;
    }

    const data = await response.json()

    localStorage.setItem('acess-token', data.access_token)
    localStorage.setItem('refresh-token', data.refresh_token)
    localStorage.setItem('expires', new Date(new Date().getTime() + 50 * 60 * 1000).toISOString())

    return true;
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email })
}

export async function discoverCurrentAccount(email: string) {
    var response = await fetch((await GetAPIDomain()) + `/discovery/current?email=${email}`, {
        headers: {
            'x-account-discovery': 'x-account-discovery',
        },
    })

    const data = await response.json()
    if (response.ok) {
        localStorage.setItem('accountId', data.id)
    }

    return { ok: response.ok, data: data }
}

export async function getssoLink(accountId: string) {
    const nonce = Math.random().toString(36).slice(2, 7)

    var accountid = localStorage.getItem('accountId')
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
        fetch(apiDomain + `/accounts/${accountId}/sign-in-link?nonce=${nonce}`, {
            headers: {
                'x-account-id': accountid || '',
            },
        })
    )
}

export async function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    const domain = localStorage.getItem('api-domain')
    var response = await httpClientWithAuth.get(domain + `/users/by-token`)

    const { data } = response
    if (!data.hasCrmAccess) {
        window.location.href = '/no-access'
    } else {
        return response
    }
}

export function getUserPermissionByToken() {
    const domain = localStorage.getItem('api-domain')
    return httpClientWithAuth.get(domain + `/permissions/for/current-user`)
}
