import {ListQueryParams, ApiResponse} from '../ApiClient'
import {GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch} from '../Helper'

export class InviteQueryParams extends ListQueryParams {
  WithStatus(value: any) {
    this.WithParam('status', value)
    return this
  }

  WithFileUploadId(value: any) {
    this.WithParam('fileUploadId', value)
    return this
  }
}

export class InvitationsClient {
  static async List(params: InviteQueryParams) {
    var query = (await GetAPIDomain()) + '/invites' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/invites/' + id, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async InviteUser(
    name: string,
    surname: string,
    email: string,
      roleId: string,
      hasCrmAccess: boolean,
      hasMobileAccess: boolean,
    teamId: string,
    userTypeId: string,
    siteId?: any,
    businessUnitId?: any,
    reportsToUserId?: any
  ) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/invites/user', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          name: name,
          surname: surname,
          email: email,
            roleId: roleId,
            hasCrmAccess,
            hasMobileAccess,
          teamId,
          userTypeId,
          siteId,
          businessUnitId,
          reportsToUserId,
        }),
      })
    )
  }

  static async AcceptInvite(code: string, password: string) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/invites/confirm/' + code, {
        method: 'post',
        headers: {
          'x-account-discovery': 'x-account-discovery',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          password: password,
        }),
      })
    )
  }

  static async ValidateCode(code: string) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/invites/validate', {
        method: 'post',
        headers: {
          'x-account-discovery': 'x-account-discovery',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          code: code,
        }),
      })
    )
  }

  static async Cancel(id: string) {
    var query = (await GetAPIDomain()) + '/invites/cancel/' + id

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'post',
      })
    )
  }

  static async Decline(id: string) {
    var query = (await GetAPIDomain()) + '/invites/decline/' + id

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'post',
      })
    )
  }

  static async Update(id: string, name: string, surname: string) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/invites/' + id, {
        method: 'patch',
        headers: GetAPIHeadersPatch(),
        body: JSON.stringify([
          {
            op: 'replace',
            path: 'name',
            value: name,
          },
          {
            op: 'replace',
            path: 'surname',
            value: surname,
          },
        ]),
      })
    )
  }

  static async BulkCreate(
    roleId: string,
    file: any,
    sendInvite: boolean,
    teamId: string,
    userTypeId: string,
    siteId?: any,
    businessUnitId?: any,
    reportsToUserId?: any
  ) {
    let formData = new FormData()
    formData.append('roleId', roleId)
    formData.append('file', file)
    formData.append('teamId', teamId)
    formData.append('userTypeId', userTypeId)

    if (siteId) formData.append('siteId', siteId)
    if (businessUnitId) formData.append('businessUnitId', businessUnitId)
    if (reportsToUserId) formData.append('reportsToUserId', reportsToUserId)

    formData.append('sendInvite', sendInvite ? 'true' : 'false')

    var accessToken = localStorage.getItem('acess-token')

    const response = await fetch((await GetAPIDomain()) + '/invites/bulk', {
      method: 'post',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: formData,
    })

    return await response.json()
  }

  static async BulkSendEmails() {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/invites/bulk/send-emails', {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + '/invites/' + id

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }
}
