import {ListQueryParams, ApiResponse} from '../ApiClient'
import {GetAPIDomain, GetAPIHeaders, GetApiHeadersFormData, GetAPIHeadersPatch} from '../Helper'

export class UserQueryParams extends ListQueryParams {
  WithShowSuspended(value: any) {
    if (!value) this.WithParam('status', 'active')
    return this
  }

  WithRoleId(value: any) {
    this.WithParam('roleId', value)
    return this
  }

  WithStatus(value: any) {
    this.WithParam('status', value)
    return this
  }

  WithTeamId(value: any) {
    this.WithParam('teamId', value)
    return this
  }

  WithSiteId(value: any) {
    this.WithParam('siteId', value)
    return this
  }

  WithReportsToId(value: any) {
    this.WithParam('reportsToId', value)
    return this
  }
  WithSearchableOnly(value: any) {
    this.WithParam('searchableOnly', value)
    return this
  }
  WithUserTypeId(value: any) {
    this.WithParam('userTypeId', value)
    return this
  }
  WithBusinessUnitId(value: any) {
    this.WithParam('businessUnitId', value)
    return this
  }
  WithSuspended(value: boolean) {
    this.WithParam('suspended', value)
    return this
  }
  WithThemeId(value: boolean) {
    this.WithParam('accountThemeId', value)
    return this
  }
  WithExcludeHasAccountThemeUsers(value: boolean) {
    this.WithParam('excludeHasAccountThemeUsers', value)
    return this
  }
  WithExcludeHasLocalizationUsers(value: boolean) {
    this.WithParam('excludeHasLocalizationUsers', value)
    return this
  }
  WithExpand(value: boolean) {
    this.WithParam('expand', value)
    return this
  }
}

export class UsersClient {
  static async List(params: UserQueryParams) {
    var query = (await GetAPIDomain()) + '/users' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async LookUp(params: UserQueryParams) {
    var query = (await GetAPIDomain()) + '/users/lookup' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/' + id + '?excludeFavorites=true', {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async GetCurrentUser(email: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/current/' + email, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async AddReportsToUsers(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/' + id + '/reports-to-users', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          userIds: userIds,
        }),
      })
    )
  }

  static async AddSkillsToUsers(id: string, skillIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/' + id + '/skills', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          skillIds,
        }),
      })
    )
  }

  static async SetRole(id: string, roleId: any) {
    var query = (await GetAPIDomain()) + '/users/' + id + '/set-roles'

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'post',
        body: JSON.stringify({
          roleIds: roleId,
        }),
      })
    )
  }

  static async SetTeam(
    userId: string,
    teamId: string,
    isPrimary: boolean = false,
    isTeamLeader: boolean = false
  ) {
    var query = (await GetAPIDomain()) + '/users/' + userId + '/set-team'

    let formData = new FormData()
    formData.append('userId', userId)
    formData.append('teamId', teamId)
    formData.append('isPrimary', isPrimary ? 'true' : 'false')
    formData.append('isTeamLeader', isTeamLeader ? 'true' : 'false')

    return await ApiResponse.Create(() =>
      fetch(query, {
        method: 'post',
        headers: GetApiHeadersFormData(),
        body: formData,
      })
    )
  }

  static async RemoveTeam(userId: string, teamId: string) {
    var query = (await GetAPIDomain()) + '/users/' + userId + '/remove-team'

    let formData = new FormData()
    formData.append('userId', userId)
    formData.append('teamId', teamId)

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetApiHeadersFormData(),
        method: 'delete',
        body: formData,
      })
    )
  }

  static async Suspend(id: string) {
    var query = (await GetAPIDomain()) + '/users/' + id + '/suspend'

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'post',
      })
    )
  }
  static async Restore(id: string) {
    var query = (await GetAPIDomain()) + '/users/' + id + '/restore'

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'post',
      })
    )
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + '/users/' + id

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async Update(
    id: string,
    name: string,
    surname: string,
    email: string,
    mobile?: string,
    siteId?: string,
    hasCrmAccess?: boolean,
    hasMobileAccess?: boolean,
    reportsToUserId?: string,
    businessUnitId?: string,
    userTypeId?: string,
    searchable?: boolean,
    suspended?: boolean
  ) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/' + id, {
        method: 'patch',
        headers: GetAPIHeadersPatch(),
        body: JSON.stringify([
          {
            op: 'replace',
            path: 'name',
            value: name,
          },
          {
            op: 'replace',
            path: 'surname',
            value: surname,
          },
          {
            op: 'replace',
            path: 'email',
            value: email,
          },
          {
            op: 'replace',
            path: 'mobile',
            value: mobile,
          },
          {
            op: 'replace',
            path: 'siteId',
            value: siteId,
          },
          {
            op: 'replace',
            path: 'hasCrmAccess',
            value: hasCrmAccess,
          },
          {
            op: 'replace',
            path: 'hasMobileAccess',
            value: hasMobileAccess,
          },
          {
            op: 'replace',
            path: 'reportsToUserId',
            value: reportsToUserId,
          },
          {
            op: 'replace',
            path: 'businessUnitId',
            value: businessUnitId,
          },
          {
            op: 'replace',
            path: 'userTypeId',
            value: userTypeId,
          },
          {
            op: 'replace',
            path: 'searchable',
            value: searchable,
          },
          {
            op: 'replace',
            path: 'suspended',
            value: suspended,
          },
        ]),
      })
    )
  }

  static async UploadPhoto(userId: string, file: any) {
    let formData = new FormData()
    formData.append('userId', userId)
    formData.append('file', file)

    var accessToken = localStorage.getItem('acess-token')

    const response = await fetch((await GetAPIDomain()) + '/users/upload-photo', {
      method: 'post',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: formData,
    })

    if (!response.ok) {
      return false
    }

    const data = await response.json()
  }

  static async DeletePhoto(id: string) {
    var query = (await GetAPIDomain()) + '/users/' + id + '/remove-photo'

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }

  static async GenerateTwoFactorAuth(email: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/generate-two-factor-auth', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          email: email,
        }),
      })
    )
  }

  static async CheckTwoFactorAuth(email: string, token: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/check-two-factor-auth', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          email: email,
          token: token,
        }),
      })
    )
  }

  //BEGIN PASSWORD RESET
  static async BeginPasswordReset(email: string) {
    const response = await fetch((await GetAPIDomain()) + '/reset-password/begin', {
      method: 'post',
      headers: new Headers({
        'content-type': 'application/json',
        'x-account-discovery': 'x-account-discovery',
      }),
      body: JSON.stringify({
        email: email,
      }),
    })

    return await response.json()
  }

  //COMPLETE PASSWORD RESET
  static async CompletePasswordReset(code: string, password: string) {
    const response = await fetch((await GetAPIDomain()) + '/reset-password/complete', {
      method: 'post',
      headers: new Headers({
        'content-type': 'application/json',
        'x-account-discovery': 'x-account-discovery',
      }),
      body: JSON.stringify({
        code: code,
        password: password,
      }),
    })

    return await response.json()
  }

  static async GetSummary(id: string, month: number, year: number) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/users/monthly-summary' + `?id=${id}&month=${month}&year=${year}`, {
        headers: GetAPIHeaders(),
      })
    )
  }
}
