import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {PermissionsClient} from '../../../infrastracture/api/PermissionsClient'
import {AccountSettingsClient} from '../../../infrastracture/api/AccountSettingsClient'
import * as accountSettings from '../../account-themes/redux/AccountSettingRedux'
import {ChartColors, ChartLabels} from '../../../common/constants/chartColors'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  const fetchAccountSettings = async () => {
    var defaultSettings = await AccountSettingsClient.GetDefault()

    const {data} = defaultSettings

    var color = data.accountThemes[0]

    if (color?.values) {
      const onSiteColor =
        color.values.find((x: any) => x.key === 'OnSite')?.value ?? ChartColors.OnSite
      const homeColor = color.values.find((x: any) => x.key === 'Remote')?.value ?? ChartColors.Home
      const noResponseColor =
        color.values.find((x: any) => x.key === 'NoResponse')?.value ?? ChartColors.NoResponse
      const notWorkingColor =
        color.values.find((x: any) => x.key === 'Leave')?.value ?? ChartColors.NotWorking
      const whatsOnHoliday = color.values.find((x: any) => x.key === 'WhatsOnHoliday')?.value
      const whatsOnEvent = color.values.find((x: any) => x.key === 'WhatsOnEvent')?.value

      var mappedColors = {
        OnSite: onSiteColor,
        Home: homeColor,
        NoResponse: noResponseColor,
        NotWorking: notWorkingColor,
        WhatsOnHoliday: whatsOnHoliday,
        WhatsOnEvent: whatsOnEvent,
      }

      dispatch(accountSettings.Actions.setColor(mappedColors))
    }

    var localization = data.localizations[0]

    if (localization?.values) {
      const onSiteLabel =
        localization.values.find((x: any) => x.key === 'OnSite')?.value ?? ChartLabels.OnSite
      const homeLabel =
        localization.values.find((x: any) => x.key === 'Home')?.value ?? ChartLabels.Home
      const noResponseLabel = ChartLabels.NoResponse
      const notWorkingLabel =
        localization.values.find((x: any) => x.key === 'NotWorking')?.value ??
        ChartLabels.NotWorking

      var mappedLabels = {
        OnSite: onSiteLabel,
        Home: homeLabel,
        NoResponse: noResponseLabel,
        NotWorking: notWorkingLabel,
      }

      dispatch(accountSettings.Actions.SetLocalization(mappedLabels))
    }
  }

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const result = (await getUserByToken()) as any

          if (result && result?.data) {
            const {data: user} = result

            dispatch(props.fulfillUser(user))

            var {data: permissions} = await PermissionsClient.ForCurrentUser()

            if (permissions.data) {
              var mappedPermissions = permissions.data.map((p: any) => p.id)
              dispatch(props.setUserPermission(mappedPermissions))
            }

            await fetchAccountSettings()
          }
        }
      } catch (error) {
        console.error(error)
        // if (!didRequest.current) {
        //   dispatch(props.logout())
        // }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      // dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
