/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {ForgotPasswordComplete} from './components/ForgotPasswordComplete'
import {Login} from './components/Login'
import {NoCRMAccess} from './components/NoCRMAccess'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {SsoLanding} from './components/SsoLanding'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/registration' component={Registration} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/password-reset/complete/:code' component={ForgotPasswordComplete} />
            <Route exact path='/sso-login/:accountId' component={SsoLanding} />
            <Redirect from='' exact={true} to='/login' />
          </Switch>
        </div>
      </div>
    </div>
  )
}
