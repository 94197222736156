import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Planner SetFilter] Action',
  ResetFilter: '[Planner ResetFilter] Action',
}

const initialPlannerState: IPlannerState = {
  filter: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    teamId: '',
    siteId: '',
  },
}

export interface IPlannerState {
  filter?: {
    startDate: string
    endDate: string
    teamId: string
    siteId: string
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Planner', whitelist: ['filter']},
  (state: IPlannerState = initialPlannerState, action: ActionWithPayload<IPlannerState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialPlannerState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
