import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Notice SetFilter] Action',
  ResetFilter: '[Notice ResetFilter] Action',
}

const initialNoticeState: INoticeState = {
  filter: {
    activeId: true,
    statusId: '',
    siteId: '',
    teamId: '',
    userTypeId: '',
    noticeTypeId: '',
  },
}

export interface INoticeState {
  filter?: {
    activeId: boolean
    statusId: string
    siteId: string
    teamId: string
    userTypeId: string
    noticeTypeId: string
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Notice', whitelist: ['filter']},
  (state: INoticeState = initialNoticeState, action: ActionWithPayload<INoticeState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialNoticeState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
