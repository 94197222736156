import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as user from '../../app/modules/users/redux/UsersRedux'
import * as siteType from '../../app/modules/site-types/redux/SiteTypesRedux'
import * as allocation from '../../app/modules/allocations/redux/AllocationsRedux'
import * as checkInStrategy from '../../app/modules/checkin-strategies/redux/CheckInStrategiesRedux'
import * as assetType from '../../app/modules/asset-types/redux/AssetTypesRedux'
import * as invite from '../../app/modules/invites/redux/InviteRedux'
import * as myDay from '../../app/modules/my-day/redux/MyDayRedux'
import * as asset from '../../app/modules/assets/redux/AssetsRedux'
import * as site from '../../app/modules/sites/redux/SitesRedux'
import * as floor from '../../app/modules/floors/redux/FloorsRedux'
import * as neighborhood from '../../app/modules/neighborhoods/redux/NeighborhoodsRedux'
import * as planner from '../../app/modules/planner/redux/PlannerRedux'
import * as dashboard from '../../app/pages/dashboard/redux/DashboardRedux'
import * as team from '../../app/modules/teams/redux/TeamRedux'
import * as booking from '../../app/modules/bookings/redux/BookingRedux'
import * as bookingplanner from '../../app/modules/bookings/redux/PlannerRedux'
import * as accountSetting from '../../app/modules/account-themes/redux/AccountSettingRedux'
import * as events from '../../app/modules/events/redux/EventsRedux'
import * as noticeType from '../../app/modules/notice-types/redux/NoticeTypesRedux'
import * as notice from '../../app/modules/notices/redux/NoticesRedux'
import * as vehicle from '../../app/modules/vehicles/redux/VehiclesRedux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: user.reducer,
  siteType: siteType.reducer,
  allocation: allocation.reducer,
  checkInStrategy: checkInStrategy.reducer,
  assetType: assetType.reducer,
  invite: invite.reducer,
  myDay: myDay.reducer,
  asset: asset.reducer,
  site: site.reducer,
  floor: floor.reducer,
  neighborhood: neighborhood.reducer,
  planner: planner.reducer,
  dashboard: dashboard.reducer,
  team: team.reducer,
  booking: booking.reducer,
  bookingplanner: bookingplanner.reducer,
  accountSetting: accountSetting.reducer,
  events: events.reducer,
  noticeType: noticeType.reducer,
  notice: notice.reducer,
  vehicle: vehicle.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    user.saga(),
    siteType.saga(),
    allocation.saga(),
    checkInStrategy.saga(),
    checkInStrategy.saga(),
    invite.saga(),
    myDay.saga(),
    asset.saga(),
    site.saga(),
    floor.saga(),
    neighborhood.saga(),
    planner.saga(),
    dashboard.saga(),
    team.saga(),
    booking.saga(),
    bookingplanner.saga(),
    accountSetting.saga(),
    events.saga(),
    noticeType.saga(),
    notice.saga(),
    vehicle.saga(),
  ])
}
