/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import clsx from 'clsx'
import {InvitationsClient, InviteQueryParams} from '../../../infrastracture/api/InvitationsClient'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import {IsAuthenticated, LogOut} from '../../auth/redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const CheckInvitationCode: React.FC = () => {
  const params: any = useParams()
  const [createSuccess, setCreateSuccess] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
  })

  const [initialValues, setInitialValues] = useState<any>({})
  const [errorMsg, setErrorMsg] = useState('')
  const [invitation, setInvitation] = useState<any>({})
  const [invite, setInvite] = useState<any>({})
  const [showNoCrmAccess, setShowNoCrmAccess] = useState<boolean>(false)

  useEffect(() => {
    if (IsAuthenticated()) {
      LogOut()
    }

    setInitialValues({
      password: '',
      confirmPassword: '',
    })
    checkValidationCode()
  }, [])

  async function checkValidationCode() {
    var result = await InvitationsClient.ValidateCode(params.id)
    if (result.data && result.data.status == 'active') {
    } else {
      history.push(`/login`)
    }
  }

  const validateConfirmPassword = (pass: any, value: any) => {
    let error = ''
    if (pass && value) {
      if (pass !== value) {
        error = 'Password not matched'
      }
    }
    return error
  }

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
              var response = await InvitationsClient.AcceptInvite(params.id, values.password)

              if (response?.validationErrors) {
                setFieldError('password', response.validationErrors[0].message)
                return
              } else {
                if (response.data.hasCrmAccess) {
                  setCreateSuccess(true)
                } else {
                  setShowNoCrmAccess(true)
                }
              }
              setSubmitting(false)
            }}
            enableReinitialize
          >
            {({errors, touched, isSubmitting, values, setFieldValue, isValid}) => (
              <>
                <SweetAlert
                  success
                  title='Success'
                  onConfirm={() => {
                    setCreateSuccess(false)
                    history.push(`/login`)
                  }}
                  confirmBtnText='Confirm'
                  cancelBtnBsStyle='light'
                  show={createSuccess}
                  showCancel={false}
                  showCloseButton
                >
                  Invitation Accepted!
                </SweetAlert>
                {showNoCrmAccess ? (
                  <Form className='form w-100'>
                    <div className='text-center fs-6 text-dark fw-bolder'>Invitation Accepted!</div>
                    {/* 
                    <div className='text-center'>
                      <a
                        className='btn btn-lg btn-primary'
                        onClick={() => {
                          setTimeout(function () {
                            var ww = window.open(window.location, '_self')
                            ww.close()
                          }, 1000)
                        }}
                      >
                        Close
                      </a>
                    </div> */}
                  </Form>
                ) : (
                  <Form className='form w-100'>
                    <div className='text-center mb-10'>
                      <div className='text-center mb-10'>
                        <img
                          alt='Logo'
                          className='h-150px logo mb-5'
                          src={toAbsoluteUrl('/media/logos/daysee_logo.png')}
                        />
                        <h1 className='text-dark mb-3'>Daysie</h1>
                      </div>
                      <div className='text-gray-400 fw-bold fs-4'>
                        Complete account setup - create your new password and click continue
                      </div>
                    </div>
                    <div className='fv-row mb-10'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                            Password
                          </label>
                        </div>
                      </div>
                      <Field
                        name='password'
                        placeholder='Password'
                        type='password'
                        className='form-control form-control-solid'
                        id='password'
                      />
                      {touched.password && errors.password && (
                        <div className='fv-plugins-message-container'>
                          <span className='text-danger' role='alert'>
                            {errors.password}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className='fv-row mb-10'>
                      <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>
                            Confirm Password
                          </label>
                        </div>
                      </div>
                      <Field
                        name='confirmPassword'
                        placeholder='Confirm Password'
                        type='password'
                        className='form-control form-control-solid'
                        id='confirmPassword'
                        validate={(value: any) => validateConfirmPassword(values.password, value)}
                      />
                      {touched.confirmPassword && errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <span className='text-danger' role='alert'>
                            {errors.confirmPassword}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        id='rd_create_tenant_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={isSubmitting || !isValid || !values.confirmPassword}
                      >
                        {!isSubmitting && <span className='indicator-label'>Continue</span>}
                        {isSubmitting && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {CheckInvitationCode}
