import { ListQueryParams, ApiResponse } from '../ApiClient'
import { GetAPIDomain, GetAPIHeaders } from '../Helper'

export class PermissionQueryParams extends ListQueryParams { }

export class PermissionsClient {
    static async List(params: PermissionQueryParams) {
        var query = (await GetAPIDomain()) + '/permissions' + params.GenerateQueryString();

        return await ApiResponse.Create(() => fetch(query, {
            headers: GetAPIHeaders(),
        })
        )
    }

    static async ListGrouped(params: PermissionQueryParams) {
        var query = (await GetAPIDomain()) + '/permissions/grouped' + params.GenerateQueryString();

        return await ApiResponse.Create(() => fetch(query, {
            headers: GetAPIHeaders(),
        })
        )
    }

    static async ForCurrentUser() {
        var apiDomain = await GetAPIDomain();
        return await ApiResponse.Create(() => fetch(apiDomain + '/permissions/for/current-user', {
            headers: GetAPIHeaders(),
        })
        )
    }
}
