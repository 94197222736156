import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import {GetAPIDomain} from '../../app/infrastracture/Helper'
import {RefreshToken} from '../../app/modules/auth/redux/AuthCRUD'

//****************************************
// INFO: THIS IS OBSOLETE, not being used at all, refer to ApiClient.ts for the api implementation
//****************************************

// export default function setupAxios(axios: any, store: any) {
//   axios.interceptors.request.use(
//     (config: any) => {
//       const {
//         auth: {accessToken},
//       } = store.getState()

//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`
//       }

//       return config
//     },
//     (err: any) => Promise.reject(err)
//   )
// }

axios.defaults.headers.common = {
  Accept: 'application/json, application/xml, text/play, text/html, *.*',
  'Content-Type': 'application/json',
}

export const httpClientWithAuth = axios.create()

//handle request
httpClientWithAuth.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('acess-token')) {
            config.headers.Authorization = `Bearer ${localStorage.getItem('acess-token')}`
        }
        if (localStorage.getItem('accountId')) {
            config.headers['x-account-id'] = localStorage.getItem('accountId') || ''
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
);

let refreshTokenPromise: Promise<boolean | undefined> // this holds any in-progress token refresh requests


const refreshAuthLogic = async (failedRequest: any) => {

    console.log("Refresh requested");

    if (!refreshTokenPromise) {
        console.log("New refresh");
        refreshTokenPromise = RefreshToken();
    }

    return refreshTokenPromise.then(r => r ? Promise.resolve() : Promise.reject());
}

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(httpClientWithAuth, refreshAuthLogic, {
  statusCodes: [401],
})
