import {RefreshToken} from '../modules/auth/redux/AuthCRUD'

export async function GetAPIDomain() {
  var domain = localStorage.getItem('api-domain')
  var domainAuth = localStorage.getItem('domain-auth')

  if (!domain || !domainAuth) {
    const response = await fetch('/app-settings/domains')
    const data = await response.json()

    domain = data?.domainApi
    domainAuth = data?.domainAuth
    localStorage.setItem('api-domain', domain)
    localStorage.setItem('domain-auth', domainAuth)
  }

  return domain
}

export async function GetAuthAPIDomain() {
  var domainAuth = localStorage.getItem('domain-auth')

  if (!domainAuth) {
    const response = await fetch('app-settings/domains')
    const data = await response.json()

    domainAuth = data.domainAuth
    if (domainAuth) localStorage.setItem('domain-auth', domainAuth)
  }

  return domainAuth
}

export function GetAPIHeaders(contentType) {
  var expiry = new Date(Date.parse(localStorage.getItem('expires')))
  var accessToken = localStorage.getItem('acess-token')
  var accountid = localStorage.getItem('accountId')
  return new Headers({
    'content-type': contentType ? contentType : 'application/json',
    Authorization: 'Bearer ' + accessToken,
    'x-account-id': accountid,
  })
}

export function GetAPIHeadersPatch() {
  var expiry = new Date(Date.parse(localStorage.getItem('expires')))
  var accessToken = localStorage.getItem('acess-token')
  var accountid = localStorage.getItem('accountId')

  return new Headers({
    'content-type': 'application/json-patch+json',
    Authorization: 'Bearer ' + accessToken,
    'x-account-id': accountid,
  })
}

export function GetApiHeadersFormData() {
  var accessToken = localStorage.getItem('acess-token')

  var accountid = localStorage.getItem('accountId')
  return new Headers({Authorization: 'Bearer ' + accessToken, 'x-account-id': accountid})
}
