import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Site SetFilter] Action',
  ResetFilter: '[Site ResetFilter] Action',
}

const initialSiteState: ISiteState = {
  filter: {
    siteTypeId: '',
    active: true,
  },
}

export interface ISiteState {
  filter?: {
    siteTypeId: string
    active: boolean
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Site', whitelist: ['filter']},
  (state: ISiteState = initialSiteState, action: ActionWithPayload<ISiteState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialSiteState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
