/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, {Dispatch, FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import TeamSelector from '../../common/components/selectors/TeamSelector'
import {TeamsClient, TeamsQueryParams} from '../../infrastracture/api/TeamsClient'
import HomePieChart from './charts/HomePieChart'
import Select from 'react-select'
import HomePieChartUsersModal from './charts/HomePieChartUsersModal'
import {RootState} from '../../../setup'
import {useSelector, shallowEqual, connect} from 'react-redux'
import HomeSitePieChart from './charts/HomeSitePieChart'
import {SiteQueryParams, SitesClient} from '../../infrastracture/api/SitesClient'
import HomePieChartSiteModal from './charts/HomePieChartSiteModal'
import {Actions} from './redux/DashboardRedux'
import TeamSummary from '../../modules/teams/components/TeamSummary'
import TeamPlanner from '../../modules/teams/components/TeamPlanner'
import {UsersClient} from '../../infrastracture/api/UsersClient'
import {RefreshToken} from '../../modules/auth/redux/AuthCRUD'

const selectStyle = {
  control: (provided: any) => ({
    ...provided,
    background: 'white',
    borderRadius: '5px',
    zIndex: 98,
  }),
  indicatorSeparator: (_: any) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
  }),
  option: (styles: any) => {
    return {
      ...styles,
    }
  },
  menuPortal: (base: any) => ({...base, zIndex: 2000}),
  singleValue: (provided: any) => ({...provided, background: 'white'}),
  placeholder: (defaultStyles: any) => ({...defaultStyles, background: 'white'}),
}

export enum DashboardViews {
  TeamView = 'Team View',
  SiteView = 'Site View',
}

interface Props {
  dashboard: any
  dispatch: Dispatch<any>
}

const DashboardPage: React.FC<Props> = ({dashboard, dispatch}) => {
  const [teams, setTeams] = useState([])
  const [sites, setSites] = useState([])
  const [teamOptions, setTeamOptions] = useState([])
  const [siteOptions, setSiteOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [initialized, setInitialized] = useState(false)
  const [trigger, setTrigger] = useState(false)

  const [viewOptions, setViewOptions] = useState([
    {
      value: DashboardViews.TeamView,
      label: DashboardViews.TeamView,
    },
    {
      value: DashboardViews.SiteView,
      label: DashboardViews.SiteView,
    },
  ])

  const defaultTeamVal = {
    value: 'All',
    label: 'All',
  }

  const defaultSiteVal = {
    value: 'All',
    label: 'All',
  }

  const {teamDate, selectedSites, siteDate, view, showPlanner} = dashboard

  const [currentView, setCurrentView] = useState(DashboardViews.TeamView)
  const [selectedTeamData, setSelectedTeamData] = useState<any>({})
  const [selectedTeamIds, setSelectedTeamIds] = useState<any>([])
  const [selectedTeams, setSelectedTeams] = useState<any>([])

  const [selectedSiteData, setSelectedSiteData] = useState<any>({})

  const [teamsToShow, setTeamsToShow] = useState([defaultTeamVal])
  const [sitesToShow, setSitesToShow] = useState([defaultSiteVal])

  const currentuser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  useEffect(() => {
    initializeData()
    // const userTeams = currentuser.teams.map((item: any) => {
    //   return {
    //     value: item.id,
    //     label: item.name,
    //   }
    // })
    // dispatch(
    //   Actions.setTeamFilter({
    //     ...dashboard,
    //     selectedTeams: userTeams,
    //   })
    // )
  }, [])

  useEffect(() => {
    setSelectedTeamIds(selectedTeams.map((team: any) => team.value))
  }, [selectedTeams])

  const initializeData = async () => {
    var expiry = new Date(Date.parse(localStorage.getItem('expires') as any))

    if (expiry !== null && expiry < new Date()) {
      await RefreshToken()
    }

    await Promise.all([fetchTeams(), fetchSites(), fetchUserData()])
    setInitialized(true)
  }

  const fetchUserData = async () => {
    var response = await UsersClient.Get(currentuser.id)

    const {data} = response
    const userTeams = data.teams.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    setSelectedTeams(userTeams)
    setTeamsToShow(userTeams)
  }

  const fetchTeams = async () => {
    setLoading(true)
    var query = new TeamsQueryParams()

    var response = await TeamsClient.List(query.Paginate(0, 999).Sort('name', 'asc'))
    if (!response.authenticated) {
    } else {
      const {data} = response

      setTeams(data?.data)

      var options: any = [
        defaultTeamVal,
        ...data.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          }
        }),
      ]
      setTeamOptions(options)
    }
    setLoading(false)
  }
  const fetchSites = async () => {
    setLoading(true)
    var query = new SiteQueryParams()

    var response = await SitesClient.List(query.Paginate(0, 999).Sort('name', 'asc'))
    if (!response.authenticated) {
    } else {
      const {data} = response

      setSites(data?.data)

      var options: any = [
        defaultTeamVal,
        ...data.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          }
        }),
      ]
      setSiteOptions(options)
    }
    setLoading(false)
  }

  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='card-title'>
        <div className='d-flex align-items-center'>
          <Select
            onChange={(values: any) => {
              const {value} = values
              dispatch(
                Actions.setView({
                  ...dashboard,
                  view: values,
                })
              )
              setCurrentView(value)
            }}
            options={viewOptions}
            styles={selectStyle}
            value={view}
            menuPortalTarget={document.body}
            className='w-300px'
          />{' '}
          {currentView === DashboardViews.TeamView && (
            <div
              className='form-check form-switch form-check-custom form-check-solid'
              style={{marginLeft: '10px'}}
            >
              <input
                className='form-check-input h-20px w-30px mr-1'
                type='checkbox'
                value=''
                checked={showPlanner}
                onChange={(e: any) => {
                  const checked = e.target.checked

                  dispatch(
                    Actions.setPlannerFilter({
                      ...dashboard,
                      showPlanner: checked,
                    })
                  )
                }}
              />{' '}
              <span style={{marginLeft: '3px'}}>Show Planner</span>
            </div>
          )}
        </div>
        <div className='d-flex align-items-center position-relative my-1'>
          {currentView === DashboardViews.TeamView && (
            <>
              <Select
                onChange={(values: any, actionMeta: any) => {
                  var {option: newSelected} = actionMeta

                  if (newSelected?.value === 'All')
                    // dispatch(
                    //   Actions.setTeamFilter({
                    //     ...dashboard,
                    //     selectedTeams: [defaultTeamVal],
                    //   })
                    // )
                    setSelectedTeams([defaultTeamVal])
                  else setSelectedTeams(values.filter((x: any) => x.value !== 'All'))
                  // dispatch(
                  //   Actions.setTeamFilter({
                  //     ...dashboard,
                  //     selectedTeams: values.filter((x: any) => x.value !== 'All'),
                  //   })
                  // )
                }}
                options={teamOptions}
                styles={selectStyle}
                value={selectedTeams}
                menuPortalTarget={document.body}
                isMulti
                isClearable={false}
                className='w-300px'
              />
              <input
                type='date'
                name='date'
                placeholder=''
                className='form-control w-300px'
                id='date'
                value={teamDate}
                style={{marginLeft: '5px'}}
                onChange={(e) => {
                  dispatch(
                    Actions.setTeamFilter({
                      ...dashboard,
                      teamDate: moment(e.target.value).format('YYYY-MM-DD'),
                    })
                  )
                }}
              />
              <button
                className='btn btn-sm btn-secondary'
                onClick={(e: any) => {
                  e.preventDefault()
                  dispatch(
                    Actions.setTeamFilter({
                      ...dashboard,
                      teamDate: moment(teamDate).subtract(1, 'day').format('YYYY-MM-DD'),
                    })
                  )
                }}
                style={{marginLeft: '5px'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
              </button>
              <button
                className='btn btn-sm btn-secondary'
                onClick={(e: any) => {
                  e.preventDefault()
                  dispatch(
                    Actions.setTeamFilter({
                      ...dashboard,
                      teamDate: moment(teamDate).add(1, 'day').format('YYYY-MM-DD'),
                    })
                  )
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr023.svg' className='svg-icon-3' />
              </button>
            </>
          )}
          {currentView === DashboardViews.SiteView && (
            <>
              <Select
                onChange={(values: any, actionMeta: any) => {
                  var {option: newSelected} = actionMeta

                  if (newSelected?.value === 'All')
                    dispatch(
                      Actions.setSiteFilter({
                        ...dashboard,
                        selectedSites: [defaultSiteVal],
                      })
                    )
                  else
                    dispatch(
                      Actions.setSiteFilter({
                        ...dashboard,
                        selectedSites: values.filter((x: any) => x.value !== 'All'),
                      })
                    )
                }}
                options={siteOptions}
                styles={selectStyle}
                value={selectedSites}
                menuPortalTarget={document.body}
                isMulti
                isClearable={false}
                className='min-w-300px'
              />
              <input
                type='siteDate'
                name='siteDate'
                placeholder=''
                className='form-control w-300px'
                id='siteDate'
                value={siteDate}
                style={{marginLeft: '5px'}}
                onChange={(e) => {
                  dispatch(
                    Actions.setSiteFilter({
                      ...dashboard,
                      siteDate: moment(e.target.value).format('YYYY-MM-DD'),
                    })
                  )
                }}
              />
              <button
                className='btn btn-sm btn-secondary'
                onClick={(e: any) => {
                  e.preventDefault()
                  dispatch(
                    Actions.setSiteFilter({
                      ...dashboard,
                      siteDate: moment(siteDate).subtract(1, 'day').format('YYYY-MM-DD'),
                    })
                  )
                }}
                style={{marginLeft: '5px'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='svg-icon-3' />
              </button>
              <button
                className='btn btn-sm btn-secondary'
                onClick={(e: any) => {
                  e.preventDefault()
                  dispatch(
                    Actions.setSiteFilter({
                      ...dashboard,
                      siteDate: moment(siteDate).add(1, 'day').format('YYYY-MM-DD'),
                    })
                  )
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr023.svg' className='svg-icon-3' />
              </button>
            </>
          )}
          <button
            className='btn btn-sm btn-secondary'
            onClick={(e: any) => {
              e.preventDefault()

              setTeamsToShow(selectedTeams)
              setSitesToShow(selectedSites)
              setTrigger(!trigger)
            }}
          >
            Run
          </button>
          {loading && (
            <div className='d-flex align-items-center'>
              <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
            </div>
          )}
        </div>
      </div>
      {currentView === DashboardViews.TeamView && initialized && (
        <>
          <div>
            {!loading && (
              <div className='row gy-5 g-xl-8'>
                {teamsToShow.find((x: any) => x.value === 'All') && (
                  <div className='col-xxl-3' onMouseOver={() => setSelectedTeamData({})}>
                    <HomePieChart
                      teamId={null}
                      teamName={'Organisation'}
                      date={teamDate}
                      trigger={trigger}
                    />
                  </div>
                )}
                {teamsToShow.find((x: any) => x.value === 'All') &&
                  teams.length > 0 &&
                  teams.length &&
                  teams.map((item: any) => {
                    return (
                      <div className='col-xxl-3' onMouseOver={() => setSelectedTeamData(item)}>
                        <HomePieChart
                          teamId={item.id}
                          teamName={item.name}
                          date={teamDate}
                          trigger={trigger}
                        />
                      </div>
                    )
                  })}
                {!teamsToShow.find((x: any) => x.value === 'All') &&
                  teams.length > 0 &&
                  teams.length &&
                  teams
                    .filter((item: any) => teamsToShow.find((x: any) => x.value === item.id))
                    .map((item: any) => {
                      return (
                        <div className='col-xxl-3'>
                          <HomePieChart
                            teamId={item.id}
                            teamName={item.name}
                            date={teamDate}
                            trigger={trigger}
                          />
                        </div>
                      )
                    })}
              </div>
            )}
          </div>
          {showPlanner && (
            <div className='row gy-5 g-xl-8 mt-3'>
              <div className='col-12'>
                <TeamPlanner
                  teamIds={selectedTeamIds}
                  fromDashboard
                  dashboardMonth={moment(teamDate).format('M')}
                  dashboardYear={moment(teamDate).format('Y')}
                  trigger={trigger}
                />
              </div>
            </div>
          )}
        </>
      )}

      {currentView === DashboardViews.SiteView && initialized && (
        <div>
          {!loading && (
            <div className='row gy-5 g-xl-8'>
              {sitesToShow.find((x: any) => x.value === 'All') && (
                <div className='col-xxl-3' onMouseOver={() => setSelectedTeamData({})}>
                  <HomeSitePieChart
                    siteId={null}
                    siteName={'Organisation'}
                    date={siteDate}
                    trigger={trigger}
                  />
                </div>
              )}
              {sitesToShow.find((x: any) => x.value === 'All') &&
                sites.length > 0 &&
                sites.length &&
                sites.map((item: any) => {
                  return (
                    <div className='col-xxl-3' onMouseOver={() => setSelectedSiteData(item)}>
                      <HomeSitePieChart
                        siteId={item.id}
                        siteName={item.name}
                        date={siteDate}
                        trigger={trigger}
                      />
                    </div>
                  )
                })}
              {!sitesToShow.find((x: any) => x.value === 'All') &&
                sites.length > 0 &&
                sites.length &&
                sites
                  .filter((item: any) => sitesToShow.find((x: any) => x.value === item.id))
                  .map((item: any) => {
                    return (
                      <div className='col-xxl-3'>
                        <HomeSitePieChart
                          siteId={item.id}
                          siteName={item.name}
                          date={siteDate}
                          trigger={trigger}
                        />
                      </div>
                    )
                  })}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default connect(({dashboard}: RootState) => ({
  dashboard,
}))(DashboardPage)
