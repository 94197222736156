import {ApiResponse, ListQueryParams} from '../ApiClient'
import {GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch} from '../Helper'

export class HomeQueryParams extends ListQueryParams {
  WithTeamIds(value: any) {
    this.WithParam('teamIds', value)
    return this
  }
  WithDate(value: any) {
    this.WithParam('date', value)
    return this
  }
  WithIncludeWeek(value: boolean) {
    this.WithParam('includeWeek', value)
    return this
  }
  WithPieChartStatus(value: any) {
    this.WithParam('pieChartStatus', value)
    return this
  }
  WithSiteId(value: any) {
    this.WithParam('siteId', value)
    return this
  }
}

export class HomeClient {
  static async PieChart(params: HomeQueryParams) {
    var query = (await GetAPIDomain()) + '/work-days/pie-chart' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }
  static async PieChartUsers(params: HomeQueryParams) {
    var query = (await GetAPIDomain()) + '/work-days/pie-chart/users' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async DayView(params: HomeQueryParams) {
    var query = (await GetAPIDomain()) + '/work-days/day-view' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async SitePieChart(params: HomeQueryParams) {
    var query = (await GetAPIDomain()) + '/work-days/pie-chart/sites' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }
}
