export enum Modes {
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}

export enum MyDayState {
  Unknown = 'unknown',
  NotWorking = 'notWorking',
  WorkFromHome = 'workFromHome',
  WorkFromOffice = 'workFromOffice',
}

export enum MyDayStatus {
  Planned = 'Planned',
  Confirmed = 'Confirmed',
}
