/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import { useLocation, useParams } from 'react-router-dom'
import { ssoLogin } from '../redux/AuthCRUD'
import { useDispatch } from 'react-redux'
import { PermissionsClient } from '../../../infrastracture/api/PermissionsClient'
import * as auth from '../redux/AuthRedux'

export function SsoLanding() {
    const location = useLocation()
    const dispatch = useDispatch()
    const params: any = useParams()

    useEffect(() => {

        console.log(location);

        const value = qs.parse(location.hash)

        const { access_token } = value

        console.log("setting access token");
        console.log(access_token + "");
        localStorage.setItem('acess-token', access_token + "")
        dispatch(auth.actions.login(access_token + ""));

        PermissionsClient.ForCurrentUser().then(function (permissions) {

            console.log(permissions);

            if (permissions.successful) {
                if (permissions.data) {
                    var mappedPermissions = permissions.data.data.map((p: any) => p.id)
                    dispatch(auth.actions.setUserPermission(mappedPermissions))
                }
            }

            window.location.href = '/dashboard';
        });

    }, [location])

    return (
        <div className='text-center '>
            <h1 className='text-dark'>Please wait while we sign you in...</h1>
        </div>
    )
}
