import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Neighborhood SetFilter] Action',
  ResetFilter: '[Neighborhood ResetFilter] Action',
}

const initialNeighborhoodState: INeighborhoodState = {
  filter: {
    status: '',
    active: true,
  },
}

export interface INeighborhoodState {
  filter?: {
    status: string
    active: boolean
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Neighborhood', whitelist: ['filter']},
  (
    state: INeighborhoodState = initialNeighborhoodState,
    action: ActionWithPayload<INeighborhoodState>
  ) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialNeighborhoodState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
