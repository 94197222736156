import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetColor: '[AccountSetting SetColor] Action',
  SetLocalization: '[AccountSetting SetLocalization] Action',
}

const initialAccountSettingState: IAccountSettingState = {
  color: {},
  localization: {},
}

export interface IAccountSettingState {
  color: any
  localization: any
}

export const reducer = persistReducer(
  {storage, key: 'v100-AccountSetting', whitelist: ['filter']},
  (
    state: IAccountSettingState = initialAccountSettingState,
    action: ActionWithPayload<IAccountSettingState>
  ) => {
    switch (action.type) {
      case actionTypes.SetColor: {
        const color = action.payload?.color
        return {...state, color}
      }

      case actionTypes.SetLocalization: {
        const localization = action.payload?.localization
        return {...state, localization}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  setColor: (color: object) => ({
    type: actionTypes.SetColor,
    payload: {color},
  }),
  SetLocalization: (localization: object) => ({
    type: actionTypes.SetLocalization,
    payload: {localization},
  }),
}

export function* saga() {}
