import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Asset SetFilter] Action',
  ResetFilter: '[Asset ResetFilter] Action',
}

const initialAssetState: IAssetState = {
  filter: {
    status: '',
    active: true,
    isDesk: false,
    assetTypeId: '',
    reserved: false,
  },
}

export interface IAssetState {
  filter?: {
    status: string
    active: boolean
    isDesk: boolean
    assetTypeId: string
    reserved: boolean
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Asset', whitelist: ['filter']},
  (state: IAssetState = initialAssetState, action: ActionWithPayload<IAssetState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialAssetState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
