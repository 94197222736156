export enum ChartColors {
  Home = '#fcc250',
  OnSite = '#00838f',
  NoResponse = '#dcf0f2',
  NotWorking = '#EF6E6C',
}

export enum ChartLabels {
  Home = 'Home',
  OnSite = 'On Site',
  NoResponse = 'No Response',
  NotWorking = 'Not Working',
}
