import {useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, Input} from 'reactstrap'
import {RootState} from '../../../../setup'
import {TableHeader} from '../../../common/components/TableHeader'
import {ChartColors} from '../../../common/constants/chartColors'
import {HomeClient, HomeQueryParams} from '../../../infrastracture/api/HomeClient'
import {PieChartStatus} from './HomePieChart'

interface Props {
  isOpen: boolean
  handleClose: () => void
  siteId: any
  siteName: string
  usersNoResponse: any[]
  usersFromHome: any[]
  usersOnSite: any[]
  usersNotWorking: any[]
  barChart: any
}

const HomePieChartSiteModal: React.FC<Props> = (props: any) => {
  const {
    isOpen,
    handleClose,
    siteId,
    siteName,
    usersNoResponse,
    usersFromHome,
    usersOnSite,
    usersNotWorking,
    barChart,
  } = props

  const colors: any = useSelector<RootState>(
    ({accountSetting}) => accountSetting.color,
    shallowEqual
  ) as any

  const localizations: any = useSelector<RootState>(
    ({accountSetting}) => accountSetting.localization,
    shallowEqual
  ) as any

  const [loading, setLoading] = useState(false)
  return (
    <Modal isOpen={isOpen} size='lg'>
      <ModalHeader>{siteName}</ModalHeader>
      <ModalBody>
        <div className='row mb-2'>{barChart}</div>
        {siteName !== 'Organisation' && (
          <div className='row pt-5 mh-250px overflow-scroll'>
            <div className='col-xxl-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <tr>
                    <th style={{backgroundColor: colors.OnSite, padding: '5px'}}>
                      {localizations.OnSite} ({usersOnSite.length})
                    </th>
                  </tr>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  {!loading && (
                    <tbody>
                      {!loading &&
                        usersOnSite.length > 0 &&
                        usersOnSite.map((item: any) => (
                          <tr key={item.id}>
                            <td>
                              {item.name && (
                                <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                  {item.name} {item.surname}
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            <div className='col-xxl-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <tr>
                    <th style={{backgroundColor: colors.Home, padding: '5px'}}>
                      {localizations.Home} ({usersFromHome.length})
                    </th>
                  </tr>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  {!loading && (
                    <tbody>
                      {!loading &&
                        usersFromHome.length > 0 &&
                        usersFromHome.map((item: any) => (
                          <tr key={item.id}>
                            <td>
                              {item.name && (
                                <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                  {item.name} {item.surname}
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            <div className='col-xxl-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <tr>
                    <th style={{backgroundColor: colors.NoResponse, padding: '5px'}}>
                      {localizations.NoResponse} ({usersNoResponse.length})
                    </th>
                  </tr>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  {!loading && (
                    <tbody>
                      {!loading &&
                        usersNoResponse.length > 0 &&
                        usersNoResponse.map((item: any) => (
                          <tr key={item.id}>
                            <td>
                              {item.name && (
                                <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                  {item.name} {item.surname}
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            <div className='col-xxl-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <tr>
                    <th style={{backgroundColor: colors.NotWorking, padding: '5px'}}>
                      {localizations.NotWorking} ({usersNotWorking.length})
                    </th>
                  </tr>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  {!loading && (
                    <tbody>
                      {!loading &&
                        usersNotWorking.length > 0 &&
                        usersNotWorking.map((item: any) => (
                          <tr key={item.id}>
                            <td>
                              {item.name && (
                                <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                  {item.name} {item.surname}
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className='btn btn-lg btn-secondary'
          onClick={(e: any) => {
            e.preventDefault()
            handleClose()
          }}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default HomePieChartSiteModal
