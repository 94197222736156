import {Weekday} from '../../common/enum/weekdays'
import {ListQueryParams, ApiResponse} from '../ApiClient'
import {GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch} from '../Helper'

export class TeamsQueryParams extends ListQueryParams {
  WithEnabled(value: boolean) {
    this.WithParam('enabled', value)
    return this
  }
  WithIncludePrivate(value: boolean) {
    this.WithParam('includePrivate', value)
    return this
  }
  WithStatus(value: string) {
    this.WithParam('status', value)
    return this
  }
  WithExcludeFavorites(value: boolean) {
    this.WithParam('excludeFavorites', value)
    return this
  }
  WithExcludeHasAccountThemeUsers(value: boolean) {
    this.WithParam('excludeHasAccountThemeUsers', value)
    return this
  }
  WithExcludeHasLocalizationUsers(value: boolean) {
    this.WithParam('excludeHasLocalizationUsers', value)
    return this
  }
  WithNoticeId(value: string) {
    this.WithParam('noticeId', value)
    return this
  }
}

export class TeamsClient {
  static async List(params: TeamsQueryParams) {
    var query = (await GetAPIDomain()) + '/teams' + params.GenerateQueryString()

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async GetMonthlyPlanner(ids: string[], month: any, year: any) {
    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/monthly-planner', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          month,
          year,
          ids: ids,
        }),
      })
    )
  }

  static async GetMonthlySummary(id: string, month: number, year: number) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + `/teams/${id}/monthly-summary?month=${month}&year=${year}`, {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async GetDaysOfWeek(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id + '/days-of-week', {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async SetDaysOfWeek(
    id: string,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean
  ) {
    const validDays: any[] = []

    if (monday) validDays.push(Weekday.Monday)
    if (tuesday) validDays.push(Weekday.Tuesday)
    if (wednesday) validDays.push(Weekday.Wednesday)
    if (thursday) validDays.push(Weekday.Thursday)
    if (friday) validDays.push(Weekday.Friday)
    if (saturday) validDays.push(Weekday.Saturday)
    if (sunday) validDays.push(Weekday.Sunday)

    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id + '/days-of-week', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({validDays}),
      })
    )
  }

  static async GetTeamExpectedSiteDays(id: string) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id + '/expected-site-days', {
        headers: GetAPIHeaders(),
      })
    )
  }

  static async SetTeamExpectedSiteDays(
    id: string,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean
  ) {
    const validDays: any[] = []

    if (monday) validDays.push(Weekday.Monday)
    if (tuesday) validDays.push(Weekday.Tuesday)
    if (wednesday) validDays.push(Weekday.Wednesday)
    if (thursday) validDays.push(Weekday.Thursday)
    if (friday) validDays.push(Weekday.Friday)
    if (saturday) validDays.push(Weekday.Saturday)
    if (sunday) validDays.push(Weekday.Sunday)

    var apiDomain = await GetAPIDomain()

    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id + '/expected-site-days', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({validDays}),
      })
    )
  }

  static async Create(
    name: string,
    description: string,
    status: string,
    isprivate: boolean,
    color: string
  ) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify({
          name: name,
          description: description,
          status: status,
          private: isprivate,
          color,
        }),
      })
    )
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + '/teams/' + id

    return await ApiResponse.Create(() =>
      fetch(query, {
        headers: GetAPIHeaders(),
        method: 'delete',
      })
    )
  }
  static async AddUsers(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id + '/add-users', {
        method: 'post',
        headers: GetAPIHeaders(),
        body: JSON.stringify(userIds),
      })
    )
  }

  static async RemoveUsers(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id + '/remove-users', {
        method: 'delete',
        headers: GetAPIHeaders(),
        body: JSON.stringify(userIds),
      })
    )
  }

  static async Update(
    id: string,
    name: string,
    description: string,
    status: string,
    isprivate: boolean,
    color: string
  ) {
    var apiDomain = await GetAPIDomain()
    return await ApiResponse.Create(() =>
      fetch(apiDomain + '/teams/' + id, {
        method: 'patch',
        headers: GetAPIHeadersPatch(),
        body: JSON.stringify([
          {
            op: 'replace',
            path: 'name',
            value: name,
          },
          {
            op: 'replace',
            path: 'description',
            value: description,
          },
          {
            op: 'replace',
            path: 'status',
            value: status,
          },
          {
            op: 'replace',
            path: 'private',
            value: isprivate,
          },
          {
            op: 'replace',
            path: 'color',
            value: color,
          },
        ]),
      })
    )
  }
}
