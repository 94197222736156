import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Vehicle SetFilter] Action',
  ResetFilter: '[Vehicle ResetFilter] Action',
}

const initialVehicleState: IVehicleState = {
  filter: {
    typeId: '',
    userId: '',
  },
}

export interface IVehicleState {
  filter?: {
    typeId: string
    userId: string
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Vehicle', whitelist: ['filter']},
  (state: IVehicleState = initialVehicleState, action: ActionWithPayload<IVehicleState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialVehicleState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
