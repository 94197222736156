import {Chart, ArcElement, Tooltip, Legend} from 'chart.js'
import React, {useEffect, useState} from 'react'
import {Bar, Doughnut} from 'react-chartjs-2'
import {setLabels} from 'react-chartjs-2/dist/utils'
import {shallowEqual, useSelector} from 'react-redux'
import {Card, CardBody} from 'reactstrap'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {ChartColors} from '../../../common/constants/chartColors'
import {HomeClient, HomeQueryParams} from '../../../infrastracture/api/HomeClient'
import HomePieChartUsersModal from './HomePieChartUsersModal'

interface Props {
  teamId?: any
  teamName: string
  date: any
  trigger: boolean
}

export const enum PieChartStatus {
  Unknown,
  NotWorking,
  WorkFromHome,
  WorkFromOffice,
  NoResponse,
}

const HomePieChart: React.FC<Props> = ({teamId, teamName, date, trigger}) => {
  const [teamData, setTeamData] = useState<any>({})

  Chart.register(ArcElement, Tooltip, Legend)

  const colors: any = useSelector<RootState>(
    ({accountSetting}) => accountSetting.color,
    shallowEqual
  ) as any

  const localizations: any = useSelector<RootState>(
    ({accountSetting}) => accountSetting.localization,
    shallowEqual
  ) as any

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showBar, setShowBar] = useState(false)

  const baseBarLabels = [
    localizations.OnSite,
    localizations.Home,
    localizations.NoResponse,
    localizations.NotWorking,
  ]
  const [barLabels, setBarLabels] = useState<any[]>(baseBarLabels)

  const baseBgColors = [colors.OnSite, colors.Home, colors.NoResponse, colors.NotWorking]

  const [barBackgroundColors, setBarBackgroundColors] = useState<any[]>(baseBgColors)
  const baseBarData = [
    teamData?.workingOnSite || 0,
    teamData?.workingFromHome || 0,
    teamData?.noResponse || 0,
    teamData?.notWorking || 0,
  ]
  const [barData, setBarData] = useState<any[]>(baseBarData)

  useEffect(() => {
    fetchData()
  }, [trigger])

  useEffect(() => {
    setBarLabels(baseBarLabels)
    setBarBackgroundColors(baseBgColors)
    setBarData(baseBarData)
    // if (teamData?.notWorkingReasons && teamData?.notWorkingReasons.length > 0) {
    //   var additionalReasons = teamData?.notWorkingReasons.map((x: any) => x.name)
    //   setBarLabels([...baseBarLabels, ...additionalReasons])

    //   var additionalColors = teamData?.notWorkingReasons.map((x: any) => '#EF6E6C')
    //   setBarBackgroundColors([...baseBgColors, ...additionalColors])

    //   var additionalData = teamData?.notWorkingReasons.map((x: any) => x.count)
    //   setBarData([...baseBarData, ...additionalData])
    // } else {
    //   setBarLabels(baseBarLabels)
    //   setBarBackgroundColors(baseBgColors)
    //   setBarData(baseBarData)
    // }
  }, [teamData])

  const fetchData = async () => {
    setLoading(true)
    var query = new HomeQueryParams()

    var formatDate = date

    var response = await HomeClient.PieChart(
      query.WithDate(formatDate).WithTeamIds(teamId).Paginate(0, 99999)
    )
    if (!response.authenticated) {
    } else {
      const {data} = response

      setTeamData(data)

      const totalData = data?.total
      setTotal(totalData)
    }
    setLoading(false)
  }

  const pieData = {
    labels: [
      localizations.OnSite,
      localizations.Home,
      localizations.NotWorking,
      localizations.NoResponse,
    ],
    datasets: [
      {
        data: [
          teamData?.workingOnSite,
          teamData?.workingFromHome,
          teamData?.notWorking,
          teamData?.noResponse,
        ],
        backgroundColor: [colors.OnSite, colors.Home, colors.NotWorking, colors.NoResponse],
      },
    ],
  }
  const pieOptions: any = {
    cutout: 55,
    // elements: {
    //   arc: {
    //     borderJoinStyle: 'miter',
    //   },
    // },
    // events: [],
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          font: {
            family: 'Poppins, Helvetica, "sans-serif"',
          },
        },
      },
      title: {
        position: 'top',
        display: true,
        text: teamName,
      },
    },
    onClick: async (evt: any, element: any) => {
      if (element.length > 0) {
        var index = element[0].index
        setSelectedIndex(index)
      }
    },
    onmouseover: async (evt: any, element: any) => {
      if (element.length > 0) {
        var index = element[0].index
        setSelectedIndex(index)
      }
    },
  }

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: true,
        text: teamName,
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  }

  const barChartData = {
    labels: barLabels,
    datasets: [
      {
        data: barData,
        backgroundColor: barBackgroundColors,
      },
    ],
  }

  return (
    <>
      <HomePieChartUsersModal
        isOpen={modalOpen}
        handleClose={() => setModalOpen(false)}
        teamName={teamName}
        usersNoResponse={teamData.noResponseUsers || []}
        usersFromHome={teamData.workingFromHomeUsers || []}
        usersOnSite={teamData.workingOnSiteUsers || []}
        usersNotWorking={teamData.notWorkingUsers || []}
        barChart={<Bar options={barOptions} data={barChartData} />}
      />
      <Card style={{height: '100%', minHeight: '300px'}}>
        <CardBody style={{padding: '1rem'}}>
          {loading && (
            <div
              className='d-flex align-items-center w-100'
              style={{marginLeft: 'calc(50% - 20px)'}}
            >
              <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
            </div>
          )}
          {!loading && (
            <table className='w-100'>
              <tr>
                <td width='50%'>
                  <span onClick={() => setModalOpen(true)} style={{cursor: 'pointer'}}>
                    <KTSVG path='/media/icons/duotune/general/gen045.svg' className='svg-icon-3' />
                  </span>
                </td>
                <td
                  width='50%'
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginLeft: 'auto',
                    marginRight: 0,
                  }}
                >
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input h-20px w-30px'
                      type='checkbox'
                      value=''
                      checked={showBar}
                      onChange={(e: any) => {
                        const checked = e.target.checked
                        setShowBar(checked)
                      }}
                    />
                  </div>
                </td>
              </tr>
            </table>
          )}
          {!loading && total > 0 && !showBar && (
            <Doughnut
              data={pieData}
              id='homePieChart'
              options={pieOptions}
              height={'300px'}
              plugins={
                [
                  // {
                  //   id: 'text',
                  //   beforeDraw: function (chart, a, b) {
                  //     var width = chart.width,
                  //       height = chart.height,
                  //       ctx = chart.ctx
                  //     ctx.restore()
                  //     ctx.font = '600 15px Bold'
                  //     ctx.textBaseline = 'middle'
                  //     ctx.fillStyle = 'gray'
                  //     var teamCount = teamData?.home + teamData?.onSite + teamData?.noResponse
                  //     var text = teamCount > 0 ? '' : 'No team members',
                  //       textX = Math.round((width - ctx.measureText(text).width) / 2),
                  //       textY = height / 2.2
                  //     ctx.fillText(text, textX, textY)
                  //     ctx.save()
                  //   },
                  // },
                ]
              }
            />
          )}
          {!loading && total > 0 && showBar && (
            <Bar options={barOptions} data={barChartData} height={'300px'} />
          )}
          {!loading && total === 0 && (
            <div className='text-center text-grey fw-bold h-100'>
              <div className='text-top' style={{color: 'gray', fontWeight: 600, paddingTop: '5px'}}>
                {teamName}
              </div>
              <div>
                <div className='text-dark h-100' style={{marginTop: 'calc(50% - 40px)'}}>
                  No team members
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default HomePieChart
