import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Booking SetFilter] Action',
  ResetFilter: '[Booking ResetFilter] Action',
}

const initialBookingState: IBookingState = {
  filter: {
    includeReleasedPrior: false,
    siteId: '',
    assetTypeId: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
}

export interface IBookingState {
  filter?: {
    includeReleasedPrior: boolean
    siteId: string
    assetTypeId: string
    startDate: string
    endDate: string
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Booking', whitelist: ['filter']},
  (state: IBookingState = initialBookingState, action: ActionWithPayload<IBookingState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialBookingState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
