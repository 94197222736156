import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div
      // className='card'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <div>
        <div>
          <img
            className='h-150px logo mb-5'
            src={toAbsoluteUrl('/media/logos/daysee_logo.png')}
            alt='Start logo'
          />
        </div>
        <div style={{textAlign: 'center'}}>Loading ...</div>
      </div>
    </div>
  )
}
