import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import {PermissionsClient} from '../../app/infrastracture/api/PermissionsClient'
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'

const MasterLayout: React.FC = ({children}) => {
  const dispatch = useDispatch()

  var accessToken = localStorage.getItem('acess-token')

  useEffect(() => {
    refreshPermissions()
  }, [accessToken])

  const refreshPermissions = async () => {
    var permissions = await PermissionsClient.ForCurrentUser()

    if (permissions && permissions.successful) {
      var mappedPermissions = permissions.data.data.map((p: any) => p.id)
      dispatch(auth.actions.setUserPermission(mappedPermissions))
    }
  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <ExploreMain /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
