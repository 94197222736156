/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {Redirect, Switch} from 'react-router-dom'
import * as auth from '../redux/AuthRedux'

export function NoCRMAccess() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(auth.actions.logout())

    localStorage.clear()
  }, [dispatch])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form className='form w-100' noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            <div className='text-center mb-10'>
              <img
                alt='Logo'
                className='h-150px logo mb-5'
                src={toAbsoluteUrl('/media/logos/daysee_logo.png')}
              />
              <h1 className='text-dark mb-3'>You have no access to the portal</h1>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
