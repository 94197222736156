import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  fill?: string
  stroke?: string
}

const KTSVG: React.FC<Props> = ({
  className = '',
  path,
  svgClassName = 'mh-50px',
  fill = '',
  stroke = '',
}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG
        src={toAbsoluteUrl(path)}
        className={svgClassName}
        fill={fill ? fill : 'current'}
        stroke={stroke ? stroke : 'current'}
        style={{
          fill: fill ? fill : 'current',
          stroke: stroke ? stroke : 'current',
        }}
      />
    </span>
  )
}

export {KTSVG}
